import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Main from "./sub-components/Main";
import "./bootstrapGrid.css";
import headerImage from "./assets/ui1.jpg";
import mix from "./assets/mix.jpg";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { purple, green } from "@material-ui/core/colors";
import configData from "./config.json";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: configData.THEME_COLORS.PRIMARY,
    },
    secondary: {
      main: configData.THEME_COLORS.PRIMARY,
    },
  },
});

function App() {
  return (
    <div>
      <div className="App">
        {/* <div
          style={{
            marginTop: 0,
            paddingTop: 15,
            fontSize: "60px",
            textAlign: "center",
            letterSpacing: "0.08em",
            fontFamily: "Brush Script MT,cursive",
            color: "white",
            minHeight: "90px",
            background: "#bd3627",
            opacity: "0.9",
          }}
        >
          <img
            style={{ left: "10px", position: "absolute" }}
            src={mix}
            alt=""
          />
          <div style={{ display: "inline-block" }}>Reset Password Portal</div>
        </div>{" "} */}
        <ThemeProvider theme={theme}>
          {/* <hr style={{ opacity: 0.2 }} /> */}
          <div style={{ marginTop: "25px" }}>
            {" "}
            <Main></Main>
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default App;
