import configData from "../config.json";

export class ApiManager{
    public static getAuthMethods=async (email:string)=>{
        
        if(configData.IS_SECURED){
           let data=await ApiManager.getSecureResults(configData.API_RESOURCES.BASE_URL_DEV2+"api/User/GetUserAuthenticationMethods",{UPN:email})
           
           if (data && data.secureData)return data.secureData
           else            
              return {error:true,message:"The Specific User could not be found"}
        }
        console.log(configData.API_RESOURCES)
        try{
            let x=await fetch(configData.BASE_URL_DEV+`api/User/GetUserAuthenticationMethods?UPN=${escape(email)}`);
              let da=await x.json();
              
              if(da){
                //   da.accountDisabled.Success=true;
                //   debugger
                return da;

              }
              else            
              return {error:true,message:"The Specific User could not be found"}

        }
       catch(e){
           return {error:true,message:"Connection Error"}
       }
    }
    public static passwordLength=async (email:any)=>{
       
        try{
            
            if(configData.IS_SECURED){
                let data=await ApiManager.getSecureResults(configData.API_RESOURCES.BASE_URL_DEV2+`api/user/checkUserGroupMembership`,{accountName:email,domain:"DOMESTIC",groupName:"PCI Password Requirements Policy"})
                console.log(data,"dataPassword");

                if (data && data.secureData)
                return data.secureData
             }
            let x=await fetch(configData.BASE_URL_DEV+`api/user/checkUserGroupMembership?accountName=${email}&domain=DOMESTIC&groupName=PCI Password Requirements Policy`);
              let da=await x.json();
              return da;
             
        }
       catch(e){
           return {error:true,message:"Connection Error"}
       }
    }
    public static verification=async (encrypted: any,recipient: any,method: any,SendCode:any,email:any)=>{


        if(configData.IS_SECURED){
            let data=await ApiManager.getSecureResults(configData.API_RESOURCES.BASE_URL_DEV2+`api/User/VerifyUserMethod`,{encrypted,recipient,method,SendCode,Upn:email})
            
            if (data && data.secureData)return data.secureData
            else            
            return {error:true,message:"Verification Failed"}
        }
        try{
            let x=await fetch(configData.BASE_URL_DEV+`api/User/VerifyUserMethod?encrypted=${(encrypted)}&recipient=${recipient}&method=${method}&SendCode=${SendCode}&Upn=${escape(email)}`);
              let da=await x.json();
            //   return da;
              if(da)
              return da;
              else            
              return {error:true,message:"Verification Failed"}

        }
       catch(e){
           return null;
       }
    }
    public static changePassword=async (ucp: any,pass: any)=>{
        let enc=btoa(pass)
        let ucp_enc=btoa(ucp)
        if(configData.IS_SECURED){
            let data=await ApiManager.getSecureResults(configData.API_RESOURCES.BASE_URL_DEV2+`api/User/ChangePassword`,{Ucp:ucp_enc,Pass:enc})
            debugger
            if (data && data.secureData)return data.secureData
            else            
            return {Success:false,message:"Error Occurred"}
        }
        try{
   
              let x=await fetch(configData.BASE_URL_DEV+`api/User/ChangePassword?Ucp=${(ucp_enc)}&Pass=${enc}`);
              let da=await x.json();
              if(da)
              return da;
              else            
              return {Success:false,message:"Error Occurred"}

        }
       catch(e){
        return {Success:false,message:"Connectivity Error"}

       }
    }
    
    public static async sendStoreLocationText(phone:any, message:any){ //this is not in use 
        let url = `${configData.BASE_URL_DEV}api/OMS/inventoryLookupTextMsg`;
              if (message !== '' && message !== null && message !==undefined && phone !== '' && phone !== null && phone !==undefined){
                
                let apiURL = url + `?phone=${phone}&payload=${message}`;
                if(configData.IS_SECURED ){
                    let data=await ApiManager.getSecureResults(url,{phone,payload:message})
                    debugger
                    if (data && data.secureData)return data.secureData
                    else            
                    return {Success:false,message:"Error Occurred"}
                }
                console.log(apiURL)
                let rawResponse = await fetch( apiURL).then(res => res.json());
                debugger
                console.log('Sending Code to (' + message + ') to ' + url, rawResponse);
                    if (typeof rawResponse === "string")
                        rawResponse = JSON.parse(rawResponse);
                    return rawResponse;
            }

    }
 
    public static async getSecureResults(url: string, params: any) {
        var apigClientFactory = require('aws-api-gateway-client').default;

        let config = {
            invokeUrl:url, // REQUIRED
            accessKey: configData.API_RESOURCES.ACCESS_KEY,
            secretKey: configData.API_RESOURCES.SECRET_KEY,
            region: configData.API_RESOURCES.REGION,
            apiKey:""
        }
        
        let apigClient = apigClientFactory.newClient(config);
        let pathParams = {};
        let pathTemplate = '';
        let method = 'GET';
        let additionalParams = {headers: {}, queryParams: params};
        let body = '';
        try {
            let response = await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body);
           
            return {
                secureData : response.data,
                ok : response.status == 200 ? true : false,
            }
        } catch (error) {
            console.log(error);
            return {
                ok : false,
            }
        }
    }
    

    public static getConfigs = async () => {
        let response =  await fetch(`/config`);
        let data = await response.json();
        console.log(data,"data");
        return data;
    }

    public static ChangePasswordAWSConnext = async (guid:any,password:any) => {
        let response =  await fetch("/ChangePassword?guid=" + guid +"&password="+ password);
        let data = await response.json();
        console.log(data,"data");
        return data;
    }
}