import React, { useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  StepConnector,
  StepLabel,
  withStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Country from "flagit";

import { TextField, StepIconProps } from "@material-ui/core";
import "../App.css";
import configData from "../config.json";
import Person from "@material-ui/icons/Person";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import Keyboard from "@material-ui/icons/Keyboard";
import { ApiManager } from "../Managers/ApiManager";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import {
  RadioButtonCheckedTwoTone,
  Visibility,
  VisibilityOff,
  Check,
  Close,
  AccountCircle,
} from "@material-ui/icons";
import { cleanup } from "@testing-library/react";
import logo from "../assets/logo.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import zxcvbn from "zxcvbn-typescript";
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";
import { debug } from "console";
var CryptoJS = require("crypto-js");
const COUNTRY_CODES = [
  {
    value: "1",
    label: "+1",
    flag: <Country countryShort="US" size="28" />,
  },
  {
    value: "91",
    label: "+91",
    flag: <Country countryShort="IN" size="28" />,
  },
  {
    value: "92",
    label: "+92",
    flag: <Country countryShort="PK" size="28" />,
  },
  {
    value: "86",
    label: "+86",
    flag: <Country countryShort="CN" size="28" />,
  },
];
const client = new SecretsManagerClient({
  region: "us-west-2",
  // credentials: {
  //   accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID
  //     ? process.env.REACT_APP_ACCESS_KEY_ID
  //     : "",
  //   secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  //     ? process.env.REACT_APP_SECRET_ACCESS_KEY
  //     : "",
  //   sessionToken: process.env.REACT_APP_SESSION_TOKEN
  //     ? process.env.REACT_APP_SESSION_TOKEN
  //     : "",
  // },
});
console.log("Updated region");
// process.env.REACT_APP_USEEVN_SECRET_MANAGER == '1' ? new SecretsManagerClient({
//   region: process.env.REACT_APP_REGION ? process.env.REACT_APP_REGION : "",
//   credentials: {
//     accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID
//       ? process.env.REACT_APP_ACCESS_KEY_ID
//       : "",
//     secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
//       ? process.env.REACT_APP_SECRET_ACCESS_KEY
//       : "",
//     sessionToken: process.env.REACT_APP_SESSION_TOKEN
//       ? process.env.REACT_APP_SESSION_TOKEN
//       : "",
//   },
// }) : new SecretsManagerClient({
//   region: "us-west-2"
// });

const command = new GetSecretValueCommand({
  SecretId: "arn:aws:secretsmanager:us-west-2:528305722778:secret:SSPR-M3WGdB",
});
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 32,
  },
  active: {
    "& $line": {
      backgroundColor: "#bd3627",
      // "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#bd3627",
      // "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 70,
    height: 70,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#bd3627",

    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#bd3627",

    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // height: "70%",
    },
    step_label_root: {
      fontSize: "1.1rem",
      color: "white",
    },
  })
);
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}))(MuiAccordionDetails);

export default function Main() {
  const [email, setEmail] = React.useState("");
  const [guid, setGuid] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("1");
  const [emailError, setEmailError] = React.useState("");
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [awsConnect, setAwsConnet] = React.useState(false);
  const [userDetail, setUserDetail] = React.useState<any>({});
  const [passwordStrength, setPasswordStrength] = React.useState<any>({
    message: "",
    error: true,
    color: "",
  });
  const [passwordCheckList, setpasswordCheckList] = React.useState({
    lowercase: false,
    uppercase: false,
    number: false,
    specialcharacter: false,
    length: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [phone, setPhone] = React.useState("");
  const [maskedEmail, setMaskedEmail] = React.useState("");
  const [code, setCode] = React.useState(""); //this is used for phone
  const [emailCode, setEmailCode] = React.useState(""); //encrypted

  const [generalError, setGeneralError] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [emailCodeError, setEmailCodeError] = React.useState("");
  const [userCode, setUserCode] = React.useState(""); //phone code entered by user
  const [EmailUserCode, setEmailUserCode] = React.useState(""); //email code entered by user
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPhone, setConfirmPhone] = React.useState("");
  const [confirmEmail, setConfirmEmail] = React.useState("");
  const [isPhoneConfirm, setIsPhoneConfirm] = React.useState(false);
  const [isEmailConfirm, setIsEmailConfirm] = React.useState(false);
  const [timer, setTimer] = React.useState(configData.PASSWORD_RESEND_TIME);
  const [passwordLength, setPasswordLength] = React.useState(10);
  const steps = getSteps();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [encryptedPhone, setEncryptedPhone] = React.useState("");
  const [encryptedEmail, setEncryptedEmail] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [isPhoneVerificationExist, setIsPhoneVerificationExist] =
    React.useState(false);
  const [isEmailVerificationExist, setIsEmailVerificationExist] =
    React.useState(false);
  var isEnvLoaded = false;
  useEffect(() => {
    document.title = "SSPR";
    // Get Configurations
    if (!isEnvLoaded) {
      ApiManager.getConfigs().then((d) => {
        isEnvLoaded = true;
        if (d) {
          if (d.SSPR) {
            const [encrypted, iv, key] = d.SSPR.split("|");
            var keyev = CryptoJS.enc.Base64.parse(key);
            var ive = CryptoJS.enc.Base64.parse(iv);
            var decrypted = CryptoJS.AES.decrypt(encrypted, keyev, { iv: ive });
            var decrypted_data = decrypted.toString(CryptoJS.enc.Utf8);
            if (process.env.NODE_ENV === 'development') {
              decrypted_data = decrypted_data.replace(/'/g, '"')
            }
            var secrets = JSON.parse(decrypted_data);
            if (secrets) {
              console.log(secrets.Region, "secrets.Region");
              configData.API_RESOURCES.ACCESS_KEY = secrets.Access_Key;
              configData.API_RESOURCES.SECRET_KEY = secrets.Secret_Key;
              configData.API_RESOURCES.BASE_URL_DEV2 = secrets.Base_URL;
              configData.API_RESOURCES.REGION = secrets.Region;
            }
          }

          // Check Password link coming from the AWS Connect
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const guid = urlParams.get('g');
          const encodedUpn = urlParams.get('q');

          console.log(guid, "guid");
          console.log(encodedUpn, "upn");

          if (guid && guid.length > 0 && encodedUpn && encodedUpn.length > 0) {
            setGuid(guid);
            setIsLoading(true);
            setActiveStep(2);
            setAwsConnet(true);
            console.log("passwordLength");
            ApiManager.passwordLength(atob(encodedUpn)).then((l: any) => {
              console.log(l, "passwordLength");
              if (l) setPasswordLength(15);
              else setPasswordLength(10);

              setIsLoading(false);

            });


          }

        }
      });
    }

  }, []);

  const handleNext = async () => {
    if (activeStep === 0) {
      let isValid = validateEmail();
      if (isValid) {
        setCountryCode("1");

        setCode("");
        setUserCode("");
        setEmailUserCode("");
        setShowPassword(false);
        setPhone("");
        setUserDetail({});
        // setExpanded(!isPhoneVerificationExist ? "panel2" : "panel1");
        setIsLoading(true);
        setGeneralError("");
        setCodeError("");
        setEmailCodeError("");
        setIsPhoneConfirm(false);
        setIsEmailConfirm(false);
        setTimer(configData.PASSWORD_RESEND_TIME);
        setConfirmPhone("");
        setConfirmEmail("");
        setIsPhoneVerificationExist(false);
        setIsEmailVerificationExist(false);
        setMaskedEmail("");
        setEncryptedEmail("");
        setEncryptedPhone("");
        setpasswordCheckList({
          lowercase: false,
          uppercase: false,
          number: false,
          specialcharacter: false,
          length: false,
        });
        setPasswordStrength({
          message: "",
          color: "",
          error: true,
        });
        setExpanded("panel1");
        let l = await ApiManager.passwordLength(email);
        if (l) setPasswordLength(15);
        else setPasswordLength(10);
        await ApiManager.getAuthMethods(email).then((u) => {
          if (u.error) {
            if (u.message == "Connection Error") {
              setGeneralError("Error in establishing connection");
              setIsLoading(false);
              return;
            }

            setIsLoading(false);
            setEmailError(u.message);
          } else {
            if (u.accountDisabled && !u.accountDisabled.Success) {
              setGeneralError(
                u.accountDisabled.Message &&
                  u.accountDisabled.Message.length > 0
                  ? u.accountDisabled.Message
                  : "Your Account is disabled, please contact your administrator"
              );
              setIsLoading(false);
              return;
            }
            setUserDetail(u);
            if (u.value && u.value.length > 0) {
              let _isPhoneVerificationExist = false;
              let _isEmailVerificationExist = false;

              u.value.forEach((element: any) => {
                if (
                  element.OdataType ==
                  "#microsoft.graph.phoneAuthenticationMethod"
                ) {
                  setIsPhoneVerificationExist(true);
                  _isPhoneVerificationExist = true;
                  setPhone(element.MaskedPhoneNumber);
                  setEncryptedPhone(element.phoneNumber);
                }
                // else {
                //   setIsPhoneVerificationExist(false);
                //   _isPhoneVerificationExist = false;
                // }
                if (
                  element.OdataType ==
                  "#microsoft.graph.emailAuthenticationMethod"
                ) {
                  setIsEmailVerificationExist(true);
                  _isEmailVerificationExist = true;
                  setMaskedEmail(element.MaskedEmail);
                  setEncryptedEmail(element.emailAddress);
                }
              });
              console.log(isEmailVerificationExist, isPhoneVerificationExist);
              if (!_isEmailVerificationExist && !_isPhoneVerificationExist) {
                setIsLoading(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

                return;
              } else if (!_isPhoneVerificationExist) {
                setExpanded("panel2");
              }

              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
              setIsPhoneVerificationExist(false);
              setIsEmailVerificationExist(false);
              setIsLoading(false);
              setExpanded("panel1");
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              // setGeneralError("Error: Phone number does not exist");
            }
          }
        });
        //
      }
    } else if (activeStep === 1) {
      setGeneralError("");
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // return
      //demo

      setpasswordCheckList({
        lowercase: false,
        uppercase: false,
        number: false,
        specialcharacter: false,
        length: false,
      });
      setPasswordStrength({
        message: "",
        color: "",
        error: true,
      });
      setPasswordError("");
      setNewPassword("");
      setNewPasswordConfirm("");
      setShowPassword(false);
      setIsLoading(true);
      if (expanded == "panel1") {
        await ApiManager.verification(code, userCode, "x", false, email).then(
          (res) => {
            console.log(res);
            if (res) {
              if (res.IsValid) {
                setIsLoading(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              } else {
                if (res.Message && res.Message.length > 0) {
                  setCodeError(res.Message);
                  setCode("");
                } else setCodeError("Inavlid Code, try again.");
              }
            } else {
              setCodeError("Error Occurred, try again.");
            }
          }
        );
      } else if (expanded == "panel2") {
        await ApiManager.verification(
          emailCode,
          EmailUserCode,
          "x",
          false,
          email
        ).then((res) => {
          console.log(res);
          if (res) {
            if (res.IsValid) {
              setIsLoading(false);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
              if (res.Message.length > 0) {
                setEmailCodeError(res.Message);
                setEmailCode("");
              } else setEmailCodeError("Inavlid Code, try again.");
            }
          } else {
            setEmailCodeError("Error Occurred, try again.");
          }
        });
      }
    } else if (activeStep === 2) {
      // alert("Finish");
      // return;
      debugger;
      setPasswordError("");
      setGeneralError("");
      if (!passwordStrength.error) {
        if (newPassword === newPasswordConfirm) {
          setIsLoading(true);
          if (awsConnect) {
            let awsConnectResponse = await ApiManager.ChangePasswordAWSConnext(guid, newPassword);
            console.log(awsConnectResponse, "awsConnectresponse");
            if (awsConnectResponse.success) {
              setIsLoading(false);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            else {
              setIsLoading(false);
              setGeneralError(awsConnectResponse.msg ? awsConnectResponse.msg : "Error");
            }
          }
          else {
            let res = await ApiManager.changePassword(email, newPassword);
            console.log(res);
            if (res.Success) {
              setIsLoading(false);

              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
              // setPasswordError(res.Message ? res.Message : "Error");
              setIsLoading(false);

              setGeneralError(res.Message ? res.Message : "Error");
            }
          }

        } else setPasswordError("Password do not match");
      }
    }
    setIsLoading(false);
  };
  const sendCode = async (p: any, c: any) => {
    let response = await ApiManager.sendStoreLocationText(p, c).then((res) => {
      return res.PublishResponse ? true : false;
    });
    return response;
  };
  const handleBack = () => {
    setGeneralError("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const validatePassword = (password: string) => {
    if (passwordLength == 15) {
      const strongRe =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-.:;<=>?@{}/^_~`|\[\]]).{15,128}$/;
      return strongRe.test(password);
    } else {
      const strongRe =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-.:;<=>?@{}/^_~`|\[\]]).{10,128}$/;
      return strongRe.test(password);
    }
  };
  const isSpecialCharacterExist = (password: string) => {
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(password);
  };
  const isLowerCaseExist = (password: string) => {
    let format = /[a-z]/;
    return format.test(password);
  };
  const isUpperCaseExist = (password: string) => {
    let format = /[A-Z]/;
    return format.test(password);
  };
  const isNumberExist = (password: string) => {
    let format = /[0-9]/;
    return format.test(password);
  };
  const validateEmail = () => {
    setEmailError("");
    let _domain = email.split("@");
    if (_domain && _domain.length > 1) {
      //1 is because if the domain is not present it will still give the length 1
      let domain = _domain[_domain.length - 1];
      let index = configData.ALLOWED_DOMAINS.indexOf(domain.toLowerCase());
      if (index >= 0) {
        const re =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (re.test(email)) {
          return true;
        } else {
          setEmailError("Invalid Email");
        }
      } else {
        setEmailError("Domain does not exist");
        return false;
      }
    } else {
      setEmailError("Invalid Email");
      return false;
    }
  };
  const handleReset = () => {
    if (awsConnect) {
      window.location.href = window.location.pathname
    }
    setEmail("");
    setCode("");
    setUserCode("");
    setEmailUserCode("");
    setCodeError("");
    setEmailCodeError("");
    setGeneralError("");
    setPhone("");
    setIsLoading(false);
    setUserDetail({});
    setEmailError("");
    setActiveStep(0);
    setPasswordError("");
    setNewPassword("");
    setNewPasswordConfirm("");
    setIsEmailConfirm(false);
    setExpanded("panel1");
    setCountryCode("1");
    setpasswordCheckList({
      lowercase: false,
      uppercase: false,
      number: false,
      specialcharacter: false,
      length: false,
    });
    setIsPhoneConfirm(false);
    setPasswordStrength({
      message: "",
      color: "",
      error: true,
    });
    setTimer(configData.PASSWORD_RESEND_TIME);
  };
  function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <Person />,
      2: <PhoneIphone />,
      3: <Keyboard />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }
  function getSteps() {
    return ["Email Verification", "Identification", "Reset Password"];
  }
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim());
    console.log(email);
  };
  const handleCodeChange_phone = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserCode(event.target.value);
    console.log(userCode);
  };
  const handleCodeChange_email = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailUserCode(event.target.value);
    console.log(EmailUserCode);
  };
  const handleConfirmPhoneChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPhone(event.target.value);
  };
  const handleConfirmEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmEmail(event.target.value);
  };
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        setCode("");
        setUserCode("");
        setEmailUserCode("");
        setConfirmPhone("");
        setConfirmEmail("");

        setCodeError("");
        setEmailCodeError("");
        setGeneralError("");
        setIsLoading(false);
        setEmailError("");
        setIsPhoneConfirm(false);
        setIsEmailConfirm(false);
        setCountryCode("1");

        setTimer(configData.PASSWORD_RESEND_TIME);
      }
      setExpanded(newExpanded ? panel : expanded);
    };

  const verifyAndGetCode = async () => {
    setTimer(configData.PASSWORD_RESEND_TIME);
    setUserCode("");
    setEmailUserCode("");
    setIsLoading(true);
    setGeneralError("");
    setCodeError("");
    setEmailCodeError("");
    console.log(
      "country",
      countryCode + confirmPhone.replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, "")
    );
    if (expanded == "panel1") {
      await ApiManager.verification(
        encryptedPhone,
        countryCode + confirmPhone.replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, ""),
        "1",
        true,
        email
      ).then((res) => {
        if (res) {
          if (res.IsValid) {
            console.log("code101", res.Code);
            setCode(res.Code);
            setIsPhoneConfirm(res.IsValid);
            setIsLoading(false);
            let t = configData.PASSWORD_RESEND_TIME;
            let x = setInterval(() => {
              setTimer((t) => t - 1);
              t = t - 1;
              if (t <= 0) {
                clearInterval(x);
              }
            }, 1000);
          } else {
            setCodeError("Phone number does not match with your account");
            setIsLoading(false);
          }
        }
      });
    } else if (expanded == "panel2") {
      await ApiManager.verification(
        encryptedEmail,
        confirmEmail,
        "0",
        true,
        email
      ).then((res) => {
        if (res) {
          if (res.IsValid) {
            console.log("code101", res.Code);
            setEmailCode(res.Code); //encrypted
            setIsEmailConfirm(res.IsValid);
            setIsLoading(false);
            let t = configData.PASSWORD_RESEND_TIME;
            let x = setInterval(() => {
              setTimer((t) => t - 1);
              t = t - 1;
              if (t <= 0) {
                clearInterval(x);
              }
            }, 1000);
          } else {
            setEmailCodeError(
              "Alternate email does not match with your account"
            );
            setIsLoading(false);
          }
        } else {
          setEmailCodeError("Error in fetching details");
          setIsLoading(false);
        }
      });
    }

    // await ApiManager.getAuthMethods(email).then((u) => {
    //   if (u.error) {
    //     setGeneralError("Error in sending code");
    //     setIsLoading(false);
    //   } else {
    //     setUserDetail(u);
    //     if (u.value && u.value.length > 0) {
    //       let p = u.value[0].phoneNumber;
    //       // let _p = p.replace(/[^a-zA-Z0-9+]/g, "");
    //       let _p = "+923217858084";
    //       setPhone(_p);
    //       // setPhone("+923217858084"); //demo

    // setIsLoading(true);
    // let _code = Math.floor(Math.random() * 90000) + 10000;
    // console.log("code:" + +_code);
    // setCode(+_code + "");
    // console.log(phone);
    // if (sendCode(phone, +_code + "")) {
    //   setIsLoading(false);
    //   setIsPhoneConfirm(true);
    //   let t = timer;
    //   let x = setInterval(() => {
    //     setTimer((timer) => timer - 1);
    //     t = t - 1;
    //     if (t <= 0) {
    //       clearInterval(x);
    //     }
    //   }, 1000);
    // } else {
    //   setGeneralError("Error in sending code");
    // }

    //     } else {
    //       setGeneralError("Error: Phone number does not exist");
    //     }
    //   }
    // });
  };
  const handleCountryCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCountryCode(event.target.value);
  };
  const handleKeypress = (e: any) => {
    if (
      !(
        isLoading ||
        (!isPhoneConfirm && activeStep === 1 && !isEmailConfirm) ||
        (activeStep == 1 && code.length <= 0 && expanded === "panel1") ||
        (activeStep == 1 && emailCode.length <= 0 && expanded === "panel2") ||
        (activeStep == 2 && passwordStrength.error) ||
        (activeStep == 2 && newPassword !== newPasswordConfirm)
      )
    ) {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        handleNext();
      }
    }
  };

  const handleVerifyAndGetCode = (e: any) => {
    if (!isLoading)
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        verifyAndGetCode();
      }
  };
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value != expanded) {
      setCode("");
      setUserCode("");
      setEmailUserCode("");
      setConfirmPhone("");
      setConfirmEmail("");

      setCodeError("");
      setEmailCodeError("");
      setGeneralError("");
      setIsLoading(false);
      setEmailError("");
      setIsPhoneConfirm(false);
      setIsEmailConfirm(false);
      setTimer(configData.PASSWORD_RESEND_TIME);
    }
    setExpanded((event.target as HTMLInputElement).value);
  };
  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <div style={{ textAlign: "center" }}>
            <TextField
              id="enter-email"
              label="Enter Email"
              className="filled-full-width"
              style={{ margin: 8 }}
              InputProps={{
                style: { fontSize: 20 },
                // startAdornment: (
                // <InputAdornment position="start">
                //   {" "}
                //   <TextField
                //     id="filled-select-currency"
                //     select
                //     // variant="filled"
                //     // label="Select"
                //     // helperText="Please select your currency"

                //     InputProps={{ disableUnderline: true }}
                //   >
                //     {[1, 2, 3].map((option) => (
                //       <MenuItem
                //         style={{ textAlign: "left" }}
                //         key={option}
                //         value={option}
                //       >
                //         {option}
                //       </MenuItem>
                //     ))}
                //   </TextField>{" "}
                // </InputAdornment>
                // ),
              }}
              placeholder="someone@somewhere.com"
              error={emailError.length > 0 ? true : false}
              helperText={emailError}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onKeyPress={handleKeypress}
              onChange={handleEmailChange}
              value={email}
            />
            <div
              style={{
                marginTop: "15px",
                color: "red",
                textAlign: "left",
                width: "80%",
                display: "inline-flex",
              }}
            >
              {generalError}
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            {!isPhoneVerificationExist && !isEmailVerificationExist ? (
              <div>
                <h2
                  className="message-heading"
                  style={{ textAlign: "left", padding: "0 10%" }}
                >
                  We're sorry
                </h2>
                <div
                  className="message-long"
                  style={{
                    marginTop: "25px",
                    marginBottom: "25px",
                    color: "#666666",
                  }}
                >
                  {
                    "You can't reset your own password because you haven’t registered the necessary security information to perform password reset."
                  }{" "}
                  <br />{" "}
                  {
                    "If you're not an administrator, you can provide this information when you contact your administrator."
                  }
                </div>
              </div>
            ) : (
              <div>
                {isPhoneVerificationExist && (
                  <Accordion
                    square
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    // expandIcon={<ExpandMoreIcon />}
                    >
                      <RadioGroup
                        aria-label="verification"
                        name="verification1"
                        value={expanded}
                        onChange={handleChangeRadio}
                      >
                        <FormControlLabel
                          aria-label="Acknowledge"
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          control={<Radio />}
                          label="Text my mobile phone"
                          value="panel1"
                        />
                      </RadioGroup>

                      {/* <Typography></Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      {isPhoneConfirm ? (
                        <div>
                          {" "}
                          <div className="message">
                            {`We texted your phone ${phone} a 5-digit code‎. Please enter the code to Proceed`}
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <TextField
                              label="Phone Verification Code"
                              className="filled-full-width"
                              style={{ margin: 8 }}
                              placeholder="123456"
                              error={codeError.length > 0 ? true : false}
                              helperText={codeError}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onKeyPress={handleKeypress}
                              onChange={handleCodeChange_phone}
                              value={userCode}
                              InputProps={{
                                endAdornment: (
                                  <Button
                                    style={{
                                      width: timer <= 0 ? "140" : "160px",
                                      fontSize:
                                        timer <= 0 ? "0.875rem" : "10px",
                                    }}
                                    color="primary"
                                    onClick={verifyAndGetCode}
                                    disabled={timer > 0 || isLoading}
                                  >
                                    {timer <= 0
                                      ? "Resend"
                                      : `Resend in ${timer} seconds`}
                                  </Button>
                                ),
                              }}

                            // variant="filled"
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <div className="message-long">
                            {`In order to protect your account, we need you to enter your complete mobile phone number ${phone} `}
                            <span style={{ fontWeight: "bold" }}>
                              (i.e 2025550180)
                            </span>
                            {` below. You will then receive a text message with a verification code which can be used to reset your password.`}
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <TextField
                              label="Enter your phone number"
                              className="filled-full-width"
                              style={{ margin: 8 }}
                              placeholder="2025550180" //{"+" + phone.substring(1)}
                              error={codeError.length > 0 ? true : false}
                              helperText={codeError}
                              margin="normal"
                              onKeyPress={handleVerifyAndGetCode}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleConfirmPhoneChange}
                              value={confirmPhone}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <TextField
                                      InputProps={{ disableUnderline: true }}
                                      select
                                      value={countryCode}
                                      onChange={handleCountryCodeChange}
                                    >
                                      {COUNTRY_CODES.map((option) => (
                                        <MenuItem
                                          style={{
                                            backgroundColor: "white",
                                            padding: "0 10px 0 0",
                                          }}
                                          key={option.value}
                                          value={option.value}
                                        >
                                          <IconButton disabled={true}>
                                            {option.flag}
                                          </IconButton>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <Button
                                    style={{ width: "275px" }}
                                    color="primary"
                                    onClick={verifyAndGetCode}
                                    disabled={
                                      confirmPhone.length +
                                      countryCode.length +
                                      1 !==
                                      phone.length || isLoading
                                    }
                                    title="Click to get code"
                                  >
                                    {"Verify & Text"}
                                  </Button>
                                ),
                              }}

                            // variant="filled"
                            />
                          </div>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                {isEmailVerificationExist && (
                  <Accordion
                    square
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                    // expandIcon={<ExpandMoreIcon />}
                    >
                      <RadioGroup
                        aria-label="verification"
                        name="verification1"
                        value={expanded}
                        onChange={handleChangeRadio}
                      >
                        <FormControlLabel
                          aria-label="Acknowledge"
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          control={<Radio />}
                          label="Email my alternate email"
                          value="panel2"
                        />
                      </RadioGroup>
                      {/* <Typography></Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      {isEmailConfirm ? (
                        <div>
                          {" "}
                          <div className="message">
                            {`We texted your email ${maskedEmail} a 5-digit code‎. Please enter the code to
              Proceed`}
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <TextField
                              label="Email Verification Code"
                              className="filled-full-width"
                              style={{ margin: 8 }}
                              placeholder="123456"
                              error={emailCodeError.length > 0 ? true : false}
                              helperText={emailCodeError}
                              margin="normal"
                              onKeyPress={handleKeypress}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleCodeChange_email}
                              value={EmailUserCode}
                              InputProps={{
                                endAdornment: (
                                  <Button
                                    style={{
                                      width: timer <= 0 ? "140" : "160px",
                                      fontSize:
                                        timer <= 0 ? "0.875rem" : "10px",
                                    }}
                                    color="primary"
                                    onClick={verifyAndGetCode}
                                    disabled={timer > 0 || isLoading}
                                  >
                                    {timer <= 0
                                      ? "Resend"
                                      : `Resend in ${timer} seconds`}
                                  </Button>
                                ),
                              }}

                            // variant="filled"
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <div className="message-long">
                            {`In order to protect your account, we need you to enter your complete alternate email address ${maskedEmail} below. You will then receive an email with a verification code which can be used to reset your password.`}
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <TextField
                              label="Enter alternate email"
                              className="filled-full-width"
                              style={{ margin: 8 }}
                              placeholder={maskedEmail}
                              error={emailCodeError.length > 0 ? true : false}
                              helperText={emailCodeError}
                              onKeyPress={handleVerifyAndGetCode}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleConfirmEmailChange}
                              value={confirmEmail}
                              InputProps={{
                                endAdornment: (
                                  <Button
                                    style={{ width: "170px" }}
                                    color="primary"
                                    onClick={verifyAndGetCode}
                                    disabled={
                                      // confirmEmail.length !== maskedEmail.length ||
                                      isLoading
                                    }
                                    title="Click to get code"
                                  >
                                    {"Verify & Text"}
                                  </Button>
                                ),
                              }}

                            // variant="filled"
                            />
                          </div>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                <div
                  style={{
                    marginTop: "15px",
                    color: "red",
                    textAlign: "left",
                    width: "80%",
                    display: "inline-flex",
                  }}
                >
                  {generalError}
                </div>
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div style={{ textAlign: "center" }}>
            <div className="password-strength-info">
              To confirm with our Strong Password policy, you are required to
              use a sufficiently strong password.
              {/* Password must be more than 7
              characters including capital & small letters, special characters
              and numbers */}
            </div>
            {true && (
              <div>
                <div
                  className="password-strength-info"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    paddingBottom: "5px",
                  }}
                >
                  Password must have
                </div>
                {/* for mobile */}
                <div className="password-strength-checklist-mobile">
                  <div className="password-strength-checklist-div">
                    at least one lower case character{" "}
                    <span>
                      {passwordCheckList.lowercase ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}
                    </span>
                  </div>
                  <div className="password-strength-checklist-div">
                    {" "}
                    at least one number{" "}
                    <span>
                      {passwordCheckList.number ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}
                    </span>
                  </div>
                  <div className="password-strength-checklist-div">
                    at least one upper case character{" "}
                    <span>
                      {passwordCheckList.uppercase ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}
                    </span>
                  </div>
                  <div className="password-strength-checklist-div">
                    {" "}
                    at least {passwordLength} characters length{" "}
                    <span>
                      {passwordCheckList.length ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}{" "}
                    </span>
                  </div>
                  <div className="password-strength-checklist-div">
                    {"at least one special character (@ # % ^ & etc)"}

                    <span>
                      {passwordCheckList.specialcharacter ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}
                    </span>
                  </div>
                </div>
                {/*below for desktop */}
                <div className="password-strength-checklist">
                  <div className="password-strength-checklist-div">
                    at least one lower case character{" "}
                    <span>
                      {passwordCheckList.lowercase ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}
                    </span>
                  </div>
                  <div className="password-strength-checklist-div">
                    {" "}
                    at least one number{" "}
                    <span>
                      {passwordCheckList.number ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}
                    </span>
                  </div>
                </div>
                <div className="password-strength-checklist">
                  <div className="password-strength-checklist-div">
                    at least one upper case character{" "}
                    <span>
                      {passwordCheckList.uppercase ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}
                    </span>
                  </div>
                  <div className="password-strength-checklist-div">
                    {" "}
                    at least {passwordLength} characters length{" "}
                    <span>
                      {passwordCheckList.length ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}{" "}
                    </span>
                  </div>
                </div>
                <div className="password-strength-checklist">
                  <div style={{ display: "inline-block", width: "100%" }}>
                    {"at least one special character (@ # % ^ & etc)"}

                    <span>
                      {passwordCheckList.specialcharacter ? (
                        <Check
                          style={{ color: "green" }}
                          className="password-strength-checklist-icon"
                        ></Check>
                      ) : (
                        ""
                        // <Close
                        //   style={{ color: "red" }}
                        //   className="password-strength-checklist-icon"
                        // ></Close>
                      )}
                    </span>
                  </div>
                  {/* <div style={{ display: "inline-block", width: "50%" }}>
                {" "}
                Error 2
              </div> */}
                </div>
              </div>
            )}
            <br></br>

            <TextField
              label="Enter New Password"
              className="filled-full-width"
              style={{ margin: 8, marginBottom: 0 }}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              helperText=""
              margin="normal"
              error={passwordError.length > 0 ? true : false}
              value={newPassword}
              onKeyPress={handleKeypress}
              onChange={handleNewPasswordChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />

            {/* <div className="password-strength-message" style={{ width: "80%" }}> */}
            {newPassword.length > 0 && (
              <div
                style={{
                  height: "5px",
                  width: "80%",
                  background: "#c8c8c1",
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    height: "5px",
                    width: "33.33%",
                    background: passwordStrength.color,
                  }}
                ></div>
                <div
                  style={{
                    display: "inline-block",
                    height: "5px",
                    width: "33.33%",
                    background:
                      passwordStrength.color !== "red"
                        ? passwordStrength.color
                        : "",
                  }}
                ></div>

                <div
                  style={{
                    display: "inline-block",
                    height: "5px",
                    width: "33.33%",
                    background:
                      passwordStrength.color !== "red" &&
                        passwordStrength.color !== "#faa711"
                        ? passwordStrength.color
                        : "",
                  }}
                ></div>
              </div>
            )}
            {newPassword.length > 0 && (
              <div
                className="password-strength-message"
                style={{ color: passwordStrength.color }}
              >
                {passwordStrength.message}
              </div>
            )}
            {/* </div> */}
            <TextField
              label="Confirm New Password"
              className="filled-full-width"
              type={showPassword ? "text" : "password"}
              style={{ margin: 8 }}
              placeholder="Password"
              helperText={passwordError}
              onKeyPress={handleKeypress}
              margin="normal"
              error={passwordError.length > 0 ? true : false}
              value={newPasswordConfirm}
              onChange={handleNewPasswordConfirmChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            {generalError.length > 0 && (
              <p className="password-general-error">{generalError}</p>
            )}
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let passwordCheckList = {
      lowercase: isLowerCaseExist(event.target.value),
      uppercase: isUpperCaseExist(event.target.value),
      number: isNumberExist(event.target.value),
      specialcharacter: isSpecialCharacterExist(event.target.value),
      length: event.target.value.length > passwordLength - 1,
    };
    setpasswordCheckList(passwordCheckList);
    if (
      !passwordCheckList.length ||
      !passwordCheckList.number ||
      !passwordCheckList.lowercase ||
      !passwordCheckList.specialcharacter ||
      !passwordCheckList.uppercase
    ) {
      setNewPassword(event.target.value);
      setPasswordStrength({
        message: "Weak Password",
        color: "red",
        error: true,
      });
      console.log(passwordStrength);
      return;
    } else {
      setNewPassword(event.target.value);
      const matcher = validatePassword(event.target.value);
      const score = zxcvbn(event.target.value).score;
      if (matcher) {
        if (score <= 1) {
          setPasswordStrength({
            message: "Weak Password",
            color: "red",
            error: false,
          });
        } else if (score == 2 || score == 3) {
          setPasswordStrength({
            message: "Moderately Strong Password",
            color: "#faa711",
            error: false,
          });
        } else if (score == 4) {
          setPasswordStrength({
            message: "Strong Password",
            color: "green",
            error: false,
          });
        }
      } else {
        setPasswordStrength({
          message: "Weak Password",
          color: "red",
          error: true,
        });
      }
      console.log(zxcvbn(event.target.value));
    }
  };
  const handleNewPasswordConfirmChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPasswordConfirm(event.target.value);
  };
  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              classes={{ label: classes.step_label_root }}
              StepIconComponent={ColorlibStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div className="row row-no-gutters">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              {" "}
              <div className="d3-card">
                <Typography
                  style={{ color: "green" }}
                  className={classes.instructions}
                >
                  <div>
                    <img src={logo} alt="" />
                  </div>
                  Password Reset Successfully
                </Typography>
                <Button onClick={handleReset}>Close</Button>
              </div>
            </div>
          </div>
        ) : (
          // end
          <div className="row row-no-gutters">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="custom-heading">
                {"Self Service Password Portal"}
              </div>
              <div className="d3-card">
                <div className={classes.instructions}>
                  <div>
                    <img src={logo} alt="" />
                  </div>
                  {getStepContent(activeStep)}
                </div>

                <div style={{ float: "right", paddingRight: "30px" }}>
                  {isLoading && <CircularProgress style={{ marginRight: 5 }} />}

                  {activeStep !== 0 && awsConnect != true && (
                    <Button
                      disabled={activeStep === 0 || isLoading}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    disabled={
                      isLoading ||
                      (!isPhoneConfirm &&
                        activeStep === 1 &&
                        !isEmailConfirm) ||
                      (activeStep == 1 &&
                        code.length <= 0 &&
                        expanded === "panel1") ||
                      (activeStep == 1 &&
                        emailCode.length <= 0 &&
                        expanded === "panel2") ||
                      (activeStep == 2 && passwordStrength.error) ||
                      (activeStep == 2 && newPassword !== newPasswordConfirm)
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        )}
      </div>
    </div>
  );
}
